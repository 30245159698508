<template>
	<div class="has-space">
		<!-- <div class="nav-pill__wrapper mb-main display-flex">
			<DatePicker
				v-model="montlyCalenderModel"
				color="#6F8C9B"
				is-dark
				:locale="
					currentLocale === 'vn'
					? 'vi'
					: currentLocale && currentLocale === 'cn'
					? 'zh'
					: currentLocale
				"
			>
				<template #default="{ inputValue, togglePopover, hidePopover }">
					<div
						class="nav-pill"
						:class="{ selected: montlyCalender.selected }"
						@click.stop="
							monthlyCalendarHandle(
							$event,
							date,
							togglePopover,
							hidePopover
							)
						"
					>
						<span v-if="montlyCalender.date === 'DATE'">
							{{ $t(montlyCalender.date) }}</span
						>
						<span v-else> {{ montlyCalender.date }}</span>
					</div>
				</template>
			</DatePicker>
			<div
				class="nav-pill"
				v-for="(o, index) in matchOptions"
				:key="index"
				:class="{ selected: o.selected }"
				@click="handleMatchOptions(o)"
			>
				{{ $t(o.name) }}
			</div>
			<div>
				<span class="league-matchtabs__reset" @click="handleReset()">{{
					$t("RESET")
				}}</span>
			</div>
		</div> -->
		
		<MatchContent
			ref="matchContentChild"
			class="match-content__wrapper "
			style="min-height: 15rem"
			type="team"
		></MatchContent>
		<!-- @changeMatchContentEvent="" -->
		<!-- <MatchContent  ref="matchContentChild"  :isStatusWidthAuto="true" :isShowStatusAtTop="true" ></MatchContent> -->
	</div>
</template>

<script>
import MatchContent from '@/components/BasketballMatchContent.vue'
import moment from "moment";
import { DatePicker } from "@/lib/v-calendar";
import config from '@/js/config.js'

import {mapGetters,mapActions} from 'vuex'

const DATE = "DATE";
let hideToggleElement;
let hideToggleTarget;
export default {
	components: {
		MatchContent,
		DatePicker,
	},
	data() {
		return {
			displayDateFormat: config.displayDateFormat,
			matchContentInterval : null,
			montlyCalender: {
				date: DATE,
				dateValue: "",
				selected: false,
				isFirstime: false,
			},
			montlyCalenderModel: "",
			selectedDate: {}, //date
			isShowClearBtn: false, //date
			matchOptions: [
				{
					name: "UPCOMING",
					selected: false,
					type: 1,
				},
				{
					name: "MOST_RECENT",
					selected: false,
					type: 2,
				},
			],
		}
	},
	created() {
		this.teamKey = this.$route.params.teamKey;
	},
	beforeUnmount() {
		clearInterval(this.matchContentInterval)
	},
	mounted() {
		this.init();
	},
	watch:{
		currentLocale: {
			deep:true,
			handler(newVal,oldVal) {
				this.init();
				// this.getLeagueListForRankingTable(newVal.leagueIdList);
			}
		},
		$route() {
			if (this.montlyCalender.dateValue != "") {
				this.montlyCalender.date = moment(this.montlyCalender.dateValue).format(
				this.displayDateFormat
				); // when change multilanguage options translate the display date also
			}
		},
		montlyCalenderModel: {
			deep: true,
			handler(newVal, oldVal) {
				if (newVal !== DATE) {
					if (typeof hideToggleElement !== "undefined") {
						setTimeout(() => hideToggleElement({ ref: hideToggleTarget }), 500); // noob fix vcalendar bug (somethimes dont close the calendar automatic);
					}

					this.montlyCalender.date = moment(
						newVal === null ? oldVal : newVal
					).format(this.displayDateFormat);
					this.montlyCalender.dateValue = newVal === null ? oldVal : newVal;
					this.montlyCalenderModel = newVal === null ? oldVal : newVal;

					//this.isShowClearBtn = true;

					this.montlyCalender.selected = true;
					this.handleResetMatchOptions();

					// this.getFixtureByLeagueData();
				}
			},
		},
	},
	computed:{
		...mapGetters([
			'currentLocale',
		]),  
	},
 

	methods: {
		init() {
			this.handleMatchOptions(this.matchOptions[0], true); // default call upcomming data, and get pass subLeagueId from querystring
			
			this.commitParamsToMatchContent();
			this.commitParamsToMatchContentIntervel();
		},

		commitParamsToMatchContentIntervel(){ 
			if (this.matchContentInterval) {
				clearInterval(this.matchContentInterval);
			}
			this.matchContentInterval = setInterval(() => {
				this.commitParamsToMatchContentInterval();              
			}, 15000);     
		},

		commitParamsToMatchContentInterval(){
			let params = {
					teamId : this.teamKey,
					isCallInterval : true
			}

			this.$refs.matchContentChild.getMatchListByTeamData(params);
		}, 

		commitParamsToMatchContent(){
			let params = {
				teamId : this.teamKey,
				isCallInterval : false
			}
			this.$refs.matchContentChild.getMatchListByTeamData(params);
		},

		monthlyCalendarHandle(e, date, toggle, hideToggle) {
			toggle({ ref: e.target });

			hideToggleTarget = e.target;
			hideToggleElement = hideToggle;
		},

		dateSelected(e, date, toggle) {
			this.selected = date;
			toggle({ ref: e.target });
		},

		handleClearDate() {
			this.montlyCalender.selected = false;
			this.isShowClearBtn = false;
			this.montlyCalender.date = DATE;
			this.montlyCalender.dateValue = "";
		},

		handleMatchOptions(o, isFirstTimeLoadData) {
			this.matchOptions.forEach((x) => {
				x.selected = false;
			});
			o.selected = true;
			this.type = o.type;

			this.handleClearDate();

			if (isFirstTimeLoadData) {
				// this.currentSubLeague.subLeagueId = this.subLeagueId;
			}

			// this.getFixtureByLeagueData();
		},

		handleResetMatchOptions() {
			this.matchOptions.forEach((x) => {
				x.selected = false;
			});
			this.type = "";
		},
	},
}
</script>

<style scoped>
.match-content__wrapper{
  width: 770px;
}
@media (max-width: 768px) {
	.match-content__wrapper{
		width: 100%;
	}
}
</style>